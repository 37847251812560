<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                    @change="filtro.caja_jer = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.caja_jer = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Caja
                  <v-autocomplete
                    v-model="filtro.caja_jer"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="cajas_user.filter(c => c.empresa == filtro.empresa && c.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados_caja"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  <v-row>
                    <v-col class="pr-0">
                      NºComprobante
                      <v-text-field
                        v-model.trim="filtro.num_comp"
                        tabindex="1"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      Nº Caja
                      <v-text-field
                        v-model.trim="filtro.num_caja"
                        tabindex="1"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="5" xl="4" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha desde
                      <FechaPicker
                        v-model="filtro.fecha_desde"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col>
                      Fecha hasta
                      <FechaPicker
                        v-model="filtro.fecha_hasta"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  <v-row>
                    <v-col cols="6">
                      Preauditado
                      <v-autocomplete
                        v-model="filtro.preaudit"
                        tabindex="1"
                        :items="opciones"
                        hide-details
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      Auditado
                      <v-autocomplete
                        v-model="filtro.audita"
                        tabindex="1"
                        :items="opciones"
                        hide-details
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipo Transacción
                  <v-autocomplete
                    v-model="filtro.tipo_trans"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_trans.filter(tc => tc.inhabilitado != 1)"
                    :readonly="!filtro.incluir"
                    :filled="!filtro.incluir"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.tipo_comp = []; filtro.tipif = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" sm="1" class="py-1">
                  <div class="d-flex justify-end">
                    {{ filtro.incluir ? 'Incluir' : 'Excluir' }}
                  </div>
                  <div class="d-flex justify-end">
                    <v-switch
                      v-model="filtro.incluir"
                      class="my-0"
                      tabindex="1"
                      hide-details
                      dense
                      @click="filtro.tipo_comp = []; filtro.tipif = null; filtro.tipo_trans = null"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="10" sm="5" md="3" class="pt-1 pb-0 mb-n3">
                  Tipo Comprobante
                  <v-autocomplete
                    v-model="filtro.tipo_comp"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="filtro.tipo_trans ? tipos_comp.filter(tc => tc.inhabilitado != 1 && tc.tipo_trans == filtro.tipo_trans) : tipos_comp.filter(tc => tc.inhabilitado != 1)"
                    :multiple="filtro.tipo_comp.filter(tc => tc.tipifica == 1).length <= 1 || !filtro.incluir"
                    :deletable-chips="filtro.tipo_comp.filter(tc => tc.tipifica == 1).length <= 1 || !filtro.incluir"
                    :small-chips="filtro.tipo_comp.filter(tc => tc.tipifica == 1).length <= 1 || !filtro.incluir"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.tipif = null"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        :title="filtro.tipo_comp.length == tipos_comp.length ? 'Quitar selección' : 'Seleccionar todos'"
                        @click="filtro.tipo_comp.length == tipos_comp.length ? filtro.tipo_comp = [] : seleccionarTiposComp()"
                      >
                        {{ filtro.tipo_comp.length == tipos_comp.length ? 'fas fa-check-square' : 'far fa-square' }}
                      </v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipificación
                  <v-autocomplete
                    v-model="filtro.tipif"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipificaciones.filter(tp => tp.comprobante == filtro.tipo_comp && tp.inhabilitado != 1)"
                    :readonly="tipos_comp.find(t => t.id == filtro.tipo_comp) ? (tipos_comp.find(t => t.id == filtro.tipo_comp).tipifica && filtro.incluir ? false : true ) : true"
                    :filled="tipos_comp.find(t => t.id == filtro.tipo_comp) ? (tipos_comp.find(t => t.id == filtro.tipo_comp).tipifica && filtro.incluir ? false : true ) : true"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="3" class="d-flex justify-center align-center pb-n3">
                  <v-checkbox
                    v-model="filtro.con_archivo"
                    label="Con archivos (demora más)"
                    class="mb-n4"
                    tabindex="1"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col> -->
                <div class="d-flex text-right mt-n3 mt-md-n16" style="width: 100%">
                  <BtnFiltro
                    :loading="load"
                    @clear="limpiar()"
                  />
                </div>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        item-key="id"
        :class="selected.length == 0 ? 'mb-12' : ''"
        :headers="headers"
        :items="cajas"
        :loading="load"
        :footer-props="{'items-per-page-options':[5, 10, 15],'show-current-page':true, 'show-first-last-page':true}"
        :items-per-page="item_per_page"
        :page="pagina"
        single-select
        dense
        @click:row="rowClick"
        @update:page="getPage"
        @update:items-per-page="getItemPerPage"
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- ultima row para el total -->
        <template slot="body.append">
          <tr class="font-weight-bold">
            <th style="font-size: 14px">Totales</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="font-size: 14px" class="text-right">
              {{ total_importe }}
            </th>
            <th style="font-size: 14px" class="text-right">
              {{ total_efectivo }}
            </th>
            <th></th>
            <th></th>
          </tr>
        </template>
        <template v-slot:[`item.tipo_comp_nombre`]="{ item }">
          {{ item.tipo_comp_nombre }}
          <v-tooltip v-if="item.tipificacion_nombre" color="secondary" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                style="margin-bottom: 2px"
                small
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>{{ item.tipificacion_nombre }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.preauditado`]="{ item }">
          <div class="d-flex justify-center">
            <v-checkbox
              v-model="item.preauditado"
              :true-value="1"
              :false-value="null"
              hide-details
              dense
              @click.stop="comparar_cambios(item.id)"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.auditado`]="{ item }">
          <div class="d-flex justify-center">
            <v-checkbox
              v-model="item.auditado"
              :true-value="1"
              :false-value="null"
              hide-details
              dense
              @click.stop="comparar_cambios(item.id)"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- DETALLE DE LA CAJA SELECCIONADA -->
      <v-expand-transition>
        <section id="detalle">
          <v-card v-if="selected.length > 0" class="mt-2 mb-8">
            <v-card-title class="d-flex justify-end pb-0 pt-1 px-2">
              <v-btn
                title="Cerrar detalle"
                icon
                small
                @click="selected = []"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-card-title>
            <!-- data table individual -->
            <v-card-title class="pt-0 px-0" style="width: 100%">
              <v-data-table
                class="mb-2"
                style="width: 100%"
                :headers="headers"
                :items="selected"
                hide-default-footer
                sort-desc
                dense
              >
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:[`item.tipo_comp_nombre`]="{ item }">
                  {{ item.tipo_comp_nombre }}
                  <v-tooltip v-if="item.tipificacion_nombre" color="secondary" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1"
                        style="margin-bottom: 2px"
                        small
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <span>{{ item.tipificacion_nombre }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.preauditado`]="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.preauditado"
                      :true-value="1"
                      :false-value="null"
                      hide-details
                      dense
                      @click.stop="comparar_cambios(item.id)"
                    ></v-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.auditado`]="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.auditado"
                      :true-value="1"
                      :false-value="null"
                      hide-details
                      dense
                      @click.stop="comparar_cambios(item.id)"
                    ></v-checkbox>
                  </div>
                </template>
              </v-data-table>
            </v-card-title>
            <!-- comentarios e imagenes -->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-row class="d-flex justify-start">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        v-model="selected[0].tipo_comp"
                        label="Tipo Comprobante"
                        item-text="nombre"
                        item-value="id"
                        :items="tipos_comp.find(tc => tc.id == selected[0].tipo_comp).inhabilitado == 1 ? tipos_comp : tipos_comp.filter(tc => tc.ingreso == tipos_comp.find(t => t.id == selected[0].tipo_comp).ingreso && tc.inhabilitado != 1)"
                        :readonly="tipos_comp.find(tc => tc.id == selected[0].tipo_comp).inhabilitado == 1 || cajas_copy.find(c => c.id == selected[0].id).tipo_transaccion == 2"
                        :filled="tipos_comp.find(tc => tc.id == selected[0].tipo_comp).inhabilitado == 1 || cajas_copy.find(c => c.id == selected[0].id).tipo_transaccion == 2"
                        outlined
                        dense
                        @change="selected[0].tipificacion = null"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0 pt-sm-3">
                      <v-autocomplete
                        v-model="selected[0].tipificacion"
                        label="Tipificación"
                        item-text="nombre"
                        item-value="id"
                        :items="tipificaciones.filter(tp => tp.comprobante == selected[0].tipo_comp && tp.inhabilitado != 1 || tp.id == selected[0].tipificacion)"
                        :readonly="tipos_comp.find(t => t.id == selected[0].tipo_comp) ? (tipos_comp.find(t => t.id == selected[0].tipo_comp).tipifica ? false : true ) : true"
                        :filled="tipos_comp.find(t => t.id == selected[0].tipo_comp) ? (tipos_comp.find(t => t.id == selected[0].tipo_comp).tipifica ? false : true ) : true"
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <!-- vista solo para gastos -->
                    <template v-if="selected[0].gasto == 1">
                      <v-col cols="3" sm="2" class="py-0">
                        <v-checkbox
                          v-model="selected[0].pais"
                          label="País"
                          class="mt-sm-0 mt-0"
                          :true-value="1"
                          :false-value="null"
                          :disabled="selected[0].estado == 1"
                          hide-details
                          dense
                          @click="selected[0].region = null; selected[0].sucursal = null; selected[0].ptovta = null"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="5" sm="4" class="py-0">
                        <v-autocomplete
                          v-model="selected[0].region"
                          label="Región"
                          item-text="nombre"
                          item-value="codigo"
                          :items="regiones"
                          :readonly="selected[0].estado == 1 || selected[0].pais == 1"
                          :filled="selected[0].estado == 1 || selected[0].pais == 1"
                          :clearable="!(selected[0].estado == 1 || selected[0].pais == 1)"
                          outlined
                          dense
                          @change="selected[0].sucursal = null; selected[0].ptovta = null; selected[0].canal = null"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="7" sm="5" class="py-0">
                        <v-autocomplete
                          v-model="selected[0].sucursal"
                          label="Sucursal"
                          item-text="nombre"
                          item-value="id"
                          :items="sucursales.filter(s => s.region == selected[0].region || selected[0].region == null)"
                          :readonly="selected[0].estado == 1 || selected[0].pais == 1"
                          :filled="selected[0].estado == 1 || selected[0].pais == 1"
                          :clearable="!(selected[0].estado == 1 || selected[0].pais == 1)"
                          outlined
                          dense
                          @change="selected[0].ptovta = null; selected[0].canal = null"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="7" class="py-0">
                        <v-autocomplete
                          v-model="selected[0].ptovta"
                          label="Punto de venta"
                          item-text="nombre"
                          item-value="id"
                          :items="all_locales.filter(l => l.sucursal == selected[0].sucursal && l.empresa == selected[0].empresa)"
                          :readonly="selected[0].estado == 1 || selected[0].pais == 1"
                          :filled="selected[0].estado == 1 || selected[0].pais == 1"
                          :clearable="!(selected[0].estado == 1 || selected[0].pais == 1)"
                          outlined
                          dense
                          @change="selected[0].canal = selected[0].ptovta ? all_locales.find(l => l.id == selected[0].ptovta).canal : null"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="7" sm="4" class="py-0">
                      <v-autocomplete
                          v-model="selected[0].canal"
                          label="Canal"
                          item-text="nombre"
                          item-value="id"
                          :items="canales.filter(c => c.id == 1 || c.id == 2)"
                          :readonly="selected[0].estado == 1 || selected[0].ptovta != null"
                          :filled="selected[0].estado == 1 || selected[0].ptovta != null"
                          :clearable="!(selected[0].estado == 1 || selected[0].ptovta != null)"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="7" sm="5" class="py-0">
                        <v-autocomplete
                          v-model="selected[0].empresa_destino"
                          label="Emitida a"
                          item-text="nombre"
                          item-value="id"
                          :items="empresas_emit"
                          :readonly="selected[0].terceros == 1"
                          :filled="selected[0].terceros == 1"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="5" sm="3" class="py-0">
                        <v-checkbox
                          v-model="selected[0].terceros"
                          label="Terceros"
                          class="mt-sm-0 mt-0"
                          :true-value="1"
                          :false-value="null"
                          hide-details
                          dense
                          @click="selected[0].empresa_destino = null"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <FechaPicker
                          v-model="selected[0].fecha_comprobante"
                          label="Fecha comprobante"
                        />
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].cuit_emisor"
                          label="CUIT Emisor"
                          outlined
                          dense
                          @blur="buscar_empresa()"
                        >
                          <template v-slot:append-outer>
                            <v-icon
                              class="mt-1"
                              color="info"
                              small
                              @click="dialog_emitida = true"
                            >
                              fas fa-search
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" class="py-0">
                        <v-text-field
                          v-model="selected[0].razon_social"
                          label="Razón Social"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-autocomplete
                          v-model="selected[0].tipo_factura"
                          label="Tipo Factura"
                          item-text="nombre"
                          item-value="id"
                          :items="tipos_gastos.filter(tg => tg.inhabilitado != 1)"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].emision"
                          label="Emisión"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].numero"
                          label="Número"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].neto"
                          label="Neto Rtdo"
                          type="number"
                          prefix="$"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].tasa_iva"
                          label="IVA"
                          type="number"
                          suffix="%"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].iva"
                          label="IVA"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                          @blur="calcular_neto()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-text-field
                          v-model="selected[0].otros_cargos"
                          label="Otros cargos"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                          @blur="calcular_neto()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" class="pt-0 pb-3">
                        <v-checkbox
                          v-model="selected[0].factura_importada"
                          label="Factura Importada"
                          class="mt-sm-0 mt-n2"
                          :true-value="1"
                          :false-value="null"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" sm="3" class="py-0">
                        <v-checkbox
                          v-model="selected[0].original"
                          label="Original"
                          class="mt-sm-0 mt-0"
                          :true-value="1"
                          :false-value="null"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].total"
                          label="Total"
                          type="number"
                          prefix="$"
                          outlined
                          dense
                          @blur="calcular_neto()"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <!-- vista solo para movimientos bancarios -->
                    <template v-if="selected[0].tipo_transaccion == 2">
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].tipo_mov_nombre"
                          label="Movimiento"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].comprobante_banco"
                          label="Comprobante"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].fecha_banco"
                          label="Fecha"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].banco_nombre"
                          label="Banco"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].cuenta_banco"
                          label="Cuenta"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].cbu"
                          label="CBU"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="8" class="py-0">
                        <v-text-field
                          v-model="selected[0].cliente_banco"
                          label="Cliente"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="py-0">
                        <v-text-field
                          v-model="selected[0].importe_banco"
                          label="Importe"
                          type="number"
                          prefix="$"
                          outlined
                          readonly
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </template>
                    <!-- comentarios de auditoria y referencia -->
                    <v-col cols="12" class="py-0">
                      <v-textarea
                        v-model="selected[0].referencia"
                        label="Referencia - Gastos"
                        rows="2"
                        auto-grow
                        outlined
                        readonly
                        filled
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-textarea
                        v-model="selected[0].obs_preauditado"
                        label="Observación Preauditado"
                        rows="3"
                        auto-grow
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-textarea
                        v-model="selected[0].obs_auditado"
                        label="Observación Auditado"
                        rows="3"
                        auto-grow
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <!-- detalle del resultado -->
                    <template v-if="selected[0].resultado == 1">
                      <v-col cols="12" sm="4" class="pb-0">
                        <v-row>
                          <v-col cols="6" sm="12" class="py-0">
                            <FechaPicker
                              v-model="selected[0].periodo_resultado"
                              label="Periodo RTDO"
                            />
                          </v-col>
                          <v-col cols="6" sm="12" class="py-0">
                            <FechaPicker
                              v-model="selected[0].periodo_financiero"
                              label="Periodo financiero"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" class="py-0">
                        <v-textarea
                          v-model="selected[0].concepto_4"
                          label="Concepto 4"
                          rows="3"
                          auto-grow
                          outlined
                          dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="4" class="py-0">
                        <v-textarea
                          v-model="selected[0].concepto_5"
                          label="Concepto 5"
                          rows="3"
                          auto-grow
                          outlined
                          dense
                        ></v-textarea>
                      </v-col>
                    </template>
                    <!-- vista de desagregos conceptos por local -->
                    <template v-if="selected[0].desagrega == 1">
                      <v-col cols="12" class="">
                        <v-card outlined>
                          <v-card-title class="body-1 pt-2">
                            Desagregos por Local y Concepto
                            <v-spacer></v-spacer>
                            <v-btn
                              title="Modificar desagregos"
                              color="indigo"
                              :disabled="(selected[0].estado == 1 && desagregos_p.length > 0) || (selected[0].estado == 1 && !selected[0].desagregos)"
                              icon
                              small
                              @click="dialog_desagrego = true"
                            >
                              <v-icon small>fas fa-pen</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-data-table
                              :items="desagregos.filter(d => d.estado != 'ELIMINADO')"
                              :headers="[
                                { text: 'Tipo Comprobante', value: 'tipo_comprobante', sortable: false },
                                { text: 'Tipificación', value: 'tipificacion', sortable: false },
                                { text: 'Punto de venta', value: 'ptovta', sortable: false },
                                { text: 'Importe', value: 'importe', align: 'end', sortable: false }
                              ]"
                              :items-per-page="-1"
                              hide-default-footer
                              dense
                            >
                              <template v-slot:[`item.importe`]="{ item }">
                                {{ formatMoney(item.importe) }}
                              </template>
                              <template v-slot:[`item.ptovta`]="{ item }">
                                {{ all_locales.find(l => l.id == item.ptovta).nombre }}
                              </template>
                              <template v-slot:[`item.tipo_comprobante`]="{ item }">
                                {{ tipos_comp.find(l => l.id == item.tipo_comprobante).nombre }}
                              </template>
                              <template v-slot:[`item.tipificacion`]="{ item }">
                                {{ tipificaciones.find(l => l.id == item.tipificacion).nombre }}
                              </template>
                              <template v-slot:no-data>
                                <v-btn
                                  v-if="selected[0].estado == 1 && !selected[0].desagregos"
                                  class="mt-4"
                                  color="info"
                                  small
                                  @click="get_desagregos()"
                                >
                                  <v-icon small left>fas fa-eye</v-icon>
                                  Ver desagregos
                                </v-btn>
                                <v-alert
                                  v-else
                                  class="mx-auto mt-4"
                                  max-width="300"
                                  type="warning"
                                  border="left"
                                  dense
                                  text
                                >
                                  No hay desagregos
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                    <!-- vista de desagregos periodo por local -->
                    <template v-if="selected[0].desagrega == 1">
                      <v-col cols="12">
                        <v-card outlined>
                          <v-card-title class="body-1 pt-2">
                            Desagregos por Local y Periodo
                            <v-spacer></v-spacer>
                            <v-btn
                              title="Modificar desagregos"
                              color="indigo"
                              :disabled="(selected[0].estado == 1 && desagregos.length > 0) || (selected[0].estado == 1 && !selected[0].desagregos)"
                              icon
                              small
                              @click="dialog_desagrego_p = true"
                            >
                              <v-icon small>fas fa-pen</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-data-table
                              :items="desagregos_p"
                              :headers="[
                                { text: 'Periodo', value: 'periodo', sortable: false },
                                { text: 'Meses', value: 'meses', align: 'center', sortable: false },
                                { text: 'Punto de venta', value: 'local_nombre', sortable: false },
                                { text: 'Importe', value: 'importe', align: 'end', sortable: false }
                              ]"
                              :items-per-page="-1"
                              item-key="local_id"
                              hide-default-footer
                              dense
                            >
                              <template v-slot:[`item.importe`]="{ item }">
                                {{ formatMoney(item.importe) }}
                              </template>
                              <template v-slot:[`item.ptovta`]="{ item }">
                                {{ all_locales.find(l => l.id == item.ptovta).nombre }}
                              </template>
                              <template v-slot:[`item.tipo_comprobante`]="{ item }">
                                {{ tipos_comp.find(l => l.id == item.tipo_comprobante).nombre }}
                              </template>
                              <template v-slot:[`item.tipificacion`]="{ item }">
                                {{ tipificaciones.find(l => l.id == item.tipificacion).nombre }}
                              </template>
                              <template v-slot:no-data>
                                <v-btn
                                  v-if="selected[0].estado == 1 && !selected[0].desagregos"
                                  class="mt-4"
                                  color="info"
                                  small
                                  @click="get_desagregos()"
                                >
                                  <v-icon small left>fas fa-eye</v-icon>
                                  Ver desagregos
                                </v-btn>
                                <v-alert
                                  v-else
                                  class="mx-auto mt-4"
                                  max-width="300"
                                  type="warning"
                                  border="left"
                                  dense
                                  text
                                >
                                  No hay desagregos
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
                <!-- galeria de imagenes -->
                <v-col cols="12" sm="6" class="text-center">
                  <v-skeleton-loader
                    v-if="load_img && !selected[0].archivos"
                    class="mx-auto"
                    max-width="300"
                    type="card"
                  ></v-skeleton-loader>
                  <template v-else>
                    <template v-if="selected[0].archivos && selected[0].archivos.length > 0">
                      <v-carousel
                        v-model="model"
                        prev-icon="fas fa-angle-left"
                        next-icon="fas fa-angle-right"
                        height="auto"
                        :show-arrows="arrow"
                        hide-delimiters
                      >
                        <div @click="$vuetify.breakpoint.smAndDown ? arrow = !arrow : ''">
                          <v-carousel-item
                            v-for="(item, index) in selected[0].archivos"
                            :key="index"
                          >
                            <div
                              v-if="item.tipo == '.PDF'"
                              :style="$vuetify.breakpoint.smAndDown ? 'height: 500px' : 'height: 750px'"
                            >
                              Página
                              <template>
                                <span :key="keyDiv">
                                  <button :disabled="page <= 1" @click="page--">❮</button>
                                  {{ page }} / {{ item.page }}
                                  <button :disabled="page >= item.page" @click="page++">❯</button>
                                </span>
                              </template>
                              <vue-pdf-embed
                                v-if="item.page > 1"
                                :ref="`pdfRef${index}`"
                                :source="`data:application/pdf;base64,${item.file}`"
                                :page="page"
                                @rendered="handleDocumentRender"
                              ></vue-pdf-embed>
                              <vue-pdf-embed
                                v-else
                                :ref="`pdfRef${index}`"
                                :source="`data:application/pdf;base64,${item.file}`"
                                @rendered="handleDocumentRender"
                              ></vue-pdf-embed>
                            </div>
                            <template v-else>
                              <v-btn
                                style="position:absolute; z-index:1;"
                                class="mt-2 ml-n5"
                                title="Rotar -90°"
                                x-small
                                fab
                                @click="rotar -= 90"
                              >
                                <v-icon small>fas fa-undo</v-icon>
                              </v-btn>
                              <v-btn
                                style="position:absolute; z-index:1;"
                                class="mt-2 ml-5"
                                title="Rotar +90°"
                                x-small
                                fab
                                @click="rotar += 90"
                              >
                                <v-icon small>fas fa-redo</v-icon>
                              </v-btn>
                              <!-- <v-btn
                                style="position:absolute; z-index:1;"
                                class="mt-2 ml-n16"
                                title="Alejar zoom"
                                x-small
                                fab
                                @click="$refs.zoomer.zoomOut()"
                              >
                                <v-icon>fas fa-search-minus</v-icon>
                              </v-btn>
                              <v-btn
                                style="position:absolute; z-index:1;"
                                class="mt-2 ml-16"
                                title="Aumentar zoom"
                                x-small
                                fab
                                @click="$refs.zoomer.zoomIn()"
                              >
                                <v-icon>fas fa-search-plus</v-icon>
                              </v-btn> -->
                              <v-zoomer
                                ref="zoomer"
                                pivot="cursor"
                                style="height: 600px"
                                :mouseWheelToZoom="false"
                              >
                                <img
                                  :src="`data:image/jpeg;base64,${item.file}`"
                                  :style="`object-fit: contain; width: 100%; height: 100%; transform: rotate(${rotar}deg);`"
                                >
                              </v-zoomer>
                            </template>
                          </v-carousel-item>
                        </div>
                      </v-carousel>
                      <div class="my-2">
                        <v-btn
                          v-for="i in selected[0].archivos.length"
                          :key="i-1"
                          icon
                          @click="model = i-1"
                        >
                          <v-icon small>{{ i-1 === model ? 'fas fa-circle' : 'far fa-circle' }}</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <v-alert
                      v-else
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No se encontraron archivos para este registro
                    </v-alert>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-expand-transition>
    </v-col>
    <!-- BOTONES FLOTANTES PARA SELECCIONAR/DESELECCIONAR LOS CHECKS DE AUDIT/PREAUDIT Y GUARDAR -->
    <template>
      <v-btn
        color="purple"
        class="mb-3"
        style="margin-right: 292px"
        title="Ver log"
        :disabled="selected.length == 0"
        :dark="selected.length > 0"
        bottom
        right
        fixed
        x-small
        fab
        @click="ver_log()"
      >
        <v-icon class="ml-1 mb-1" small>fas fa-user-clock</v-icon>
      </v-btn>
      <v-btn
        color="info"
        class="mb-2"
        style="margin-right: 232px"
        title="Registro anterior"
        :disabled="selected.length == 0"
        :dark="selected.length > 0"
        bottom
        right
        fixed
        small
        fab
        @click="row_anterior()"
      >
        <v-icon>fas fa-arrow-left</v-icon>
      </v-btn>
      <v-btn
        color="info"
        class="mb-2"
        style="margin-right: 182px"
        title="Siguiente registro"
        :disabled="selected.length == 0"
        :dark="selected.length > 0"
        bottom
        right
        fixed
        small
        fab
        @click="siguiente_row()"
      >
        <v-icon>fas fa-arrow-right</v-icon>
      </v-btn>
      <v-btn
        class="mb-2"
        style="margin-right: 128px"
        :color="cajas.filter(c => c.preauditado == 1).length == cajas.length ? 'orange' : 'pink accent-1'"
        :title="cajas.filter(c => c.preauditado == 1).length == cajas.length ? 'Limpiar selección (PREAUDITAR)' : 'Seleccionar todas (PREAUDITAR)'"
        :disabled="cajas.length == 0"
        :dark="cajas.length > 0"
        bottom
        right
        fixed
        small
        fab
        @click="seleccionar_preauditar(cajas.filter(c => c.preauditado == 1).length == cajas.length ? 1 : 0)"
      >
        <v-icon>fas fa-{{ cajas.filter(c => c.preauditado == 1).length == cajas.length ? 'times' : 'check' }}</v-icon>
      </v-btn>
      <v-btn
        class="mb-2"
        style="margin-right: 78px"
        :color="cajas.filter(c => c.auditado == 1).length == cajas.length ? 'orange' : 'pink accent-1'"
        :title="cajas.filter(c => c.auditado == 1).length == cajas.length ? 'Limpiar selección (AUDITAR)' : 'Seleccionar todas (AUDITAR)'"
        :disabled="cajas.length == 0"
        :dark="cajas.length > 0"
        bottom
        right
        fixed
        small
        fab
        @click="seleccionar_auditar(cajas.filter(c => c.auditado == 1).length == cajas.length ? 1 : 0)"
      >
        <v-icon>fas fa-{{ cajas.filter(c => c.auditado == 1).length == cajas.length ? 'times' : 'check-double' }}</v-icon>
      </v-btn>
      <v-btn
        color="success"
        class="mr-2"
        title="Guardar cambios"
        :disabled="cajas.length == 0"
        :dark="cajas.length != 0"
        bottom
        right
        fixed
        fab
        @click="guardar()"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </template>
    <ModalGastosEmpresas
      v-model="dialog_emitida"
      @seleccion="seleccionar_empresa"
    />
    <!-- Modal Desagregos por Concepto y Local -->
    <ModalDesagregos
      v-model="dialog_desagrego"
      :p_caja="selected[0]"
      @desagrego="modificar_desagregos"
    />
    <!-- Modal Desagregos por Periodo y Local -->
    <ModalDesagregosP 
      v-model="dialog_desagrego_p"
      :p_caja="selected[0]"
      @desagrego="modificar_desagregos_p"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { format_money, parseDate } from '../../util/utils'
import moment from 'moment'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import ModalGastosEmpresas from '../../components/auditoria/ModalGastosEmpresas'
import ModalDesagregos from '../../components/auditoria/ModalDesagregos'
import ModalDesagregosP from '../../components/auditoria/ModalDesagregosP.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  data () {
    return {
      formatMoney: format_money,
      panel: 0,
      total_importe: 0,
      total_efectivo: 0,
      model: 0,
      rotar: 0,
      item_per_page: 10,
      pagina: 1,
      page: 1,
      keyDiv: 0,
      arrow: true,
      load: false,
      load_img: false,
      cambios: false,
      dialog_emitida: false,
      dialog_desagrego: false,
      dialog_desagrego_p: false,
      cajas: [],
      cajas_copy: [],
      modificados: [],
      selected: [],
      desagregos: [],
      desagregos_p: [],
      opciones: [
        { text: 'SI', value: 1 },
        { text: 'NO', value: 0 }
      ],
      headers: [
        { text: 'Nº Caja', value: 'num_caja', align: 'end', sortable: false  },
        { text: 'Nº Comp.', value: 'num_comp', align: 'end', sortable: false  },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Tipo Comp.', value: 'tipo_comp_nombre', sortable: false  },
        { text: 'Descripción', value: 'descripcion', sortable: false  },
        { text: 'Nombre Caja', value: 'caja', sortable: false  },
        { text: 'Importe', value: 'importe', align: 'end', sortable: false,  formatter: format_money },
        { text: 'Efectivo', value: 'efectivo', align: 'end', sortable: false,  formatter: format_money },
        { text: 'Preaudit.', value: 'preauditado', align: 'center', sortable: false  },
        { text: 'Audit.', value: 'auditado', align: 'center', sortable: false },
      ],
      filtro: {
        empresa: null,
        sucursal: null,
        caja_jer: null,
        estado: null,
        num_comp: null,
        num_caja: null,
        tipo_trans: null,
        tipo_comp: [],
        tipif: null,
        preaudit: null,
        audita: null,
        incluir: true,
        fecha_desde: null,
        fecha_hasta: null
      }
    }
  },
  async created () {
    // obtiene el filtro guardado del local
    let filtro = localStorage.getItem('fto_cc')
    if (filtro) {
      this.filtro = JSON.parse(filtro)
    }
    // si existe el item mdf_cc (MoDiFicacion Control Caja) es pq hay cambios in guardar
    let cambios = localStorage.getItem('mdf_cc')
    if (cambios) {
      this.cambios = true
    }
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    this.$store.state.loading = true
    await this.$store.dispatch('get_canales')
    await this.$store.dispatch('get_regiones')
    await this.$store.dispatch('get_all_locales')
    await this.$store.dispatch('caja/get_cajas_user')
    await this.$store.dispatch('caja/get_estados_caja')
    await this.$store.dispatch('controlCaja/get_tipos')
    await this.$store.dispatch('controlCaja/get_tipos_gastos')
    await this.$store.dispatch('controlCaja/get_empresas')
    await this.$store.dispatch('controlCaja/get_tipos_trans')
    this.$store.state.loading = false
    // si la url vino con filtros, filtra los datos
    const req = this.$route.params.filtro
    if (req) {
      const [empresa, sucursal, num_comp, tipo_tr] = req.split(',')
      this.filtro.empresa = parseInt(empresa)
      this.filtro.sucursal = parseInt(sucursal)
      this.filtro.num_comp = num_comp
      this.filtro.tipo_trans = parseInt(tipo_tr)
      await this.buscar()
    }
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales', 'all_locales', 'canales', 'regiones']),
    ...mapState('controlCaja', ['tipos_comp', 'tipificaciones', 'tipos_gastos', 'empresas_emit', 'tipos_trans']),
    ...mapState('caja', ['cajas_user', 'estados_caja'])
  },
  components: {
    BtnFiltro,
    FechaPicker,
    ModalGastosEmpresas,
    ModalDesagregos,
    ModalDesagregosP,
    VuePdfEmbed
  },
  watch: {
    model () {
      this.page = 1
    },
    async selected (newVal, oldVal) {
      // compara se se hicieron cambios en el registro anterior
      if (oldVal.length == 1 && (newVal.length == 0 || oldVal[0].id != newVal[0].id) && this.cajas.length > 0) {
        this.comparar_cambios(oldVal[0].id)
      }
      // obtiene las imagenes del registro seleccionado solo cuando lo selecciona por primera vez
      if (newVal.length == 1 && ((oldVal.length == 0) || (oldVal[0].id != newVal[0].id))) {
        const id = await JSON.parse(JSON.stringify(this.selected[0].id))
        let selected = await this.cajas.find(c => c.id == id)
        if (!selected.archivos) {
          this.load_img = true
          await this.$store.dispatch('controlCaja/get_archivos', selected.id)
            .then((res) => {
              selected.archivos = res.data
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load_img = false
        }
      }
      // setea los desagregos
      if (this.selected[0] && this.selected[0].desagregos) {
        if (this.selected[0].desagregos.length > 0){
          if (this.selected[0].desagregos[0].hasOwnProperty('periodo')){
            this.desagregos = []
            this.desagregos_p = JSON.parse(JSON.stringify(this.selected[0].desagregos))
          }else{
            this.desagregos = JSON.parse(JSON.stringify(this.selected[0].desagregos))
            this.desagregos_p = []
          }
        }else{
          this.desagregos = []
          this.desagregos_p = []
        }
      } else {
        this.desagregos = []
        this.desagregos_p = []
      }
    }
  },
  methods: {
    ver_log () {
      this.$store.dispatch('show_snackbar', {
        text: `
        <div>
          <div>
            Grabado por ${this.selected[0].usuario_log} el ${moment(this.selected[0].fecha_log).format('DD/MM/YYYY [a las] HH:mm:ss')}
          </div>
          ${this.selected[0].usuario_preaudit != null ? `
          <div>
            Preauditado por ${this.selected[0].usuario_preaudit} el ${moment(this.selected[0].fecha_preaudit).format('DD/MM/YYYY [a las] HH:mm:ss')}
          </div>`: ''}
          ${this.selected[0].usuario_audit != null ? `
          <div>
            Auditado por ${this.selected[0].usuario_audit} el ${moment(this.selected[0].fecha_audit).format('DD/MM/YYYY [a las] HH:mm:ss')}
          </div>`: ''}
        </div>
        `,
        color: 'purple lighten-1',
        timeout: 6000
      })
    },
    modificar_desagregos (desagregos) {
      // si la longitud de desagregos es == 0 es pq agrego y se arrepintio o nunca agrego nada
      // si ya vino con desagregos la longitud jamas va a ser 0 pq si elimina solo le cambia el estado
      this.desagregos_p = []
      if (desagregos.length != 0) {
        this.selected[0].desagregos = JSON.parse(JSON.stringify(desagregos))
        this.desagregos = JSON.parse(JSON.stringify(this.selected[0].desagregos))
        // busca la longitud es mayor a 0 busca si hay ELIMINADOS para setear el valor real del estado
        let eliminados = 0
        for (const desagrego of desagregos) {
          if (desagrego.estado == 'ELIMINADO') {
            eliminados ++
          }
        }
        // si la cantidad de eliminados es igual al tamaño del array es pq elimino todos entonces pone el estado en null
        if (eliminados == desagregos.length) {
          this.selected[0].estado = null
        } else {
          this.selected[0].estado = 1
        }
      } else {
        delete this.selected[0].desagregos
        this.desagregos = []
        this.selected[0].estado = null
      }
    },
    modificar_desagregos_p(desagregos){
      this.desagregos = []
      if (desagregos.length > 0){
        this.selected[0].desagregos = JSON.parse(JSON.stringify(desagregos))
        this.desagregos_p = JSON.parse(JSON.stringify(desagregos))
        this.selected[0].estado = 1
      }else{
        this.selected[0].estado = null
        delete this.selected[0].desagregos
        this.desagregos_p = []
      }
    },
    async get_desagregos () {
      this.$store.state.loading = true
      this.desagregos = []
      this.desagregos_p = []
      await this.$store.dispatch('controlCaja/get_desagregos', {num_comp: this.selected[0].num_comp})
        .then((res) => {
          let caja = this.cajas_copy.find(cc => cc.id == this.selected[0].id)
          if (caja) {
            caja.desagregos = []
            if (res.data.length > 0){
              caja.desagregos = res.data
              this.selected[0].desagregos = JSON.parse(JSON.stringify(caja.desagregos))
              this.desagregos = JSON.parse(JSON.stringify(this.selected[0].desagregos))
              this.desagregos_p = []
            }else if (res.data2.length > 0){
              caja.desagregos = res.data2
              this.selected[0].desagregos = JSON.parse(JSON.stringify(caja.desagregos))
              this.desagregos = []
              this.desagregos_p = JSON.parse(JSON.stringify(this.selected[0].desagregos))
            }else{
              this.selected[0].desagregos = JSON.parse(JSON.stringify(caja.desagregos))
              this.desagregos = []
              this.desagregos_p = []
            }
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    seleccionarTiposComp () {
      this.filtro.tipo_comp = []
      this.tipos_comp.forEach(comp => {
        this.filtro.tipo_comp.push(comp.id)
      })
    },
    siguientePagina (index) {
      if ((index + 1) % this.item_per_page == 0) {
        this.pagina += 1
      }
    },
    paginaAnterior (index) {
      if ((index + 1) % this.item_per_page == 1 && index != 0) {
        this.pagina -= 1
      }
    },
    getPage (val) {
      this.pagina = val
    },
    getItemPerPage (val) {
      this.item_per_page = val
    },
    async guardar () {
      // si quedo 1 seleccionado revisa si lo modifico
      if (this.selected.length == 1) {
        this.comparar_cambios(this.selected[0].id)
      }
      if (this.modificados.length > 0) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlCaja/guardar', { comprobantes: this.modificados })
          .then((res) => {
            localStorage.removeItem('mdf_cc')
            this.cambios = false
            this.filtrar()
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No hay cambios para guardar',
          color: 'warning'
        })
      }
    },
    async buscar_empresa () {
      if (this.selected[0].cuit_emisor) {
        this.$store.state.loading = true
        await this.$store.dispatch('controlCaja/get_gastos_empresas', { cuit: this.selected[0].cuit_emisor, solo_uno: 1 })
          .then((res) => {
            this.selected[0].empresa_emisora = res.data[0].id
            this.selected[0].razon_social = res.data[0].nombre
          })
          .catch(error => {
            this.selected[0].empresa_emisora = null
            this.selected[0].razon_social = null
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async buscar () {
      // validaciones
      if (!this.filtro.empresa) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
        return
      }

      // si no selecciono una sucursal, tiene que seleccionar por lo menos Nº comp, Nº caja, Tipo trans o tipo de comp
      if (!this.filtro.sucursal && !this.filtro.num_comp && !this.filtro.num_caja && !this.filtro.tipo_trans && !(this.filtro.tipo_comp.length > 0) && !this.filtro.fecha_desde) {
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione por lo menos un filtro más o un rango de fechas',
          color: 'warning'
        })
        return
      } else if (!(this.filtro.sucursal || this.filtro.num_comp || this.filtro.num_caja || this.filtro.tipo_trans || this.filtro.tipo_comp.length > 0)) {
        // si no selecciono ninguno de los anteriores tiene que ingresar un rango de fechas no mayor a 31 dias
          
        let fecha = moment(new Date())
        if (this.filtro.fecha_hasta) fecha = parseDate(this.filtro.fecha_hasta)

        if (moment(fecha).diff(moment(parseDate(this.filtro.fecha_desde)), 'days') > 31) {
          this.$store.dispatch('show_snackbar', {
            text: 'El intervalo de fechas no debe exceder un mes',
            color: 'warning'
          })
          return
        }
      }

      // si hay cambios sin guardarmuestra una alerta para cargar lo que quedo
      if (this.cambios) {
        await this.$swal.fire({
          icon: 'warning',
          title: `Se encontraron cambios sin guardar ¿Desea cargarlos?`,
          html: `(Si selecciona <strong>No</strong> se perderán los cambios que quedaron sin guardar)`,
          width: '650px',
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          showCancelButton: true,
          allowEnterKey: false
        })
          .then((res) => {
            if (res.isConfirmed) {
              this.selected = []
              this.modificados = []
              this.total_importe = 0
              this.total_efectivo = 0
              this.cajas = JSON.parse(localStorage.getItem('mdf_cc'))
              this.cajas_copy = JSON.parse(localStorage.getItem('mdf_cc'))
              this.calcular_totales()
            }
            else if (res.dismiss === this.$swal.DismissReason.cancel) {
              localStorage.removeItem('mdf_cc')
              this.cambios = false
              this.filtrar()
            }
          })
      } else {
        this.filtrar()
      }
    },
    async filtrar () {
      this.cajas = []
      this.selected = []
      this.modificados = []
      this.total_importe = 0
      this.total_efectivo = 0
      this.load = true
      localStorage.setItem('fto_cc', JSON.stringify(this.filtro))
      await this.$store.dispatch('controlCaja/get_cajas', this.filtro)
        .then((res) => {
          this.cajas = res.data
          this.cajas_copy = JSON.parse(JSON.stringify(this.cajas))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
      this.calcular_totales()
    },
    seleccionar_empresa (empresa) {
      this.selected[0].empresa_emisora = empresa.id
      this.selected[0].cuit_emisor = empresa.cuit
      this.selected[0].razon_social = empresa.nombre
    },
    comparar_cambios (id) {
      let caja = JSON.parse(JSON.stringify(this.cajas.find(c => c.id == id)))
      delete caja.archivos
      if (JSON.stringify(caja) != JSON.stringify(this.cajas_copy.find(cc => cc.id == id))) {
        let modificado = this.modificados.find(m => m.id == id)
        if (modificado) {
          this.modificados.splice(this.modificados.indexOf(modificado), 1)
        } 
        this.modificados.push(caja)
        localStorage.setItem('mdf_cc', JSON.stringify(this.modificados))
        this.cambios = true
      } else {
        let modificado = this.modificados.find(m => m.id == id)
        if (modificado) {
          this.modificados.splice(this.modificados.indexOf(modificado), 1)
          localStorage.setItem('mdf_cc', JSON.stringify(this.modificados))
          if (this.modificados.length == 0) {
            localStorage.removeItem('mdf_cc')
            this.cambios = false
          }
        }
      }
    },
    calcular_neto () {
      const iva = this.selected[0].iva
      const impuestos = this.selected[0].otros_cargos
      const total = this.selected[0].total
      if (!(iva === '' || iva === null) && !(impuestos === '' || impuestos === null)) {
        let neto = total - parseFloat(iva) - parseFloat(impuestos)
        if (neto > 0) {
          this.selected[0].neto = neto
        } else {
          let caja = this.cajas_copy.find(c => c.id == this.selected[0].id)
          this.selected[0].iva = caja.iva
          this.selected[0].otros_cargos = caja.otros_cargos
          this.selected[0].total = caja.total
          this.$store.dispatch('show_snackbar', {
            text: 'El monto neto no puede ser menor o igual que 0',
            color: 'warning'
          })
        }
      }
    },
    calcular_totales () {
      // calcula los totales
      this.cajas.forEach(caja => {
        this.total_importe += caja.importe
        this.total_efectivo += caja.efectivo
      })
      this.total_importe = format_money(this.total_importe)
      this.total_efectivo = format_money(this.total_efectivo)
    },
    seleccionar_auditar (limpiar) {
      if (this.cajas.length > 1000) {
        this.$store.dispatch('show_snackbar', {
          text: 'No puede auditar más de mil regitros a la vez',
          color: 'warning'
        })
        return
      }
      for (let caja of this.cajas) {
        caja.auditado = limpiar == 1 ? null : 1
        this.comparar_cambios(caja.id)
      }
    },
    seleccionar_preauditar (limpiar) {
      if (this.cajas.length > 1000) {
        this.$store.dispatch('show_snackbar', {
          text: 'No puede preauditar más de mil regitros a la vez',
          color: 'warning'
        })
        return
      }
      for (let caja of this.cajas) {
        caja.preauditado = limpiar == 1 ? null : 1
        this.comparar_cambios(caja.id)
      }
    },
    siguiente_row () {
      if (this.selected.length == 1) {
        const index = this.cajas.indexOf(this.selected[0])
        if (index < this.cajas.length - 1) {
          this.siguientePagina(index)
          this.selected = [this.cajas[index + 1]]
          this.rotar = 0
        }
      }
    },
    row_anterior () {
      if (this.selected.length == 1) {
        const index = this.cajas.indexOf(this.selected[0])
        if (index > 0) {
          this.paginaAnterior(index)
          this.selected = [this.cajas[index - 1]]
          this.rotar = 0
        }
      }
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
      if (!row.isSelected) this.$vuetify.goTo('#detalle')
    },
    handleDocumentRender() {
      this.selected[0].archivos[this.model].page = this.$refs[`pdfRef${this.model}`][0].pageCount
      this.keyDiv++
    },
    limpiar () {
      localStorage.removeItem('fto_cc')
      this.filtro = {
        empresa: null,
        sucursal: null,
        caja_jer: null,
        estado: null,
        num_comp: null,
        num_caja: null,
        tipo_trans: null,
        tipo_comp: [],
        tipif: null,
        preaudit: null,
        audita: null,
        incluir: true,
        fecha_desde: null,
        fecha_hasta: null
      }
    }
  }
}
</script>