<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <v-card :disabled="load">
      <v-card-title>
        Editar empresa
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8 pb-0">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="empresa.nombre"
                label="Razón social"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="empresa.cuit"
                label="CUIT"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Modal para editar empresas de gasto. Emite la accion @editar al padre cuando se modifica
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      empresa: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_empresa: {}
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        this.empresa = JSON.parse(JSON.stringify(this.p_empresa))
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (JSON.stringify(this.empresa) != JSON.stringify(this.p_empresa)) {
          this.load = true
          await this.$store.dispatch('controlCaja/editar_empresa', this.empresa)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.empresa.nombre = this.empresa.nombre.toUpperCase()
              this.$emit('editar', this.empresa)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        } else {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se realizaron cambios en la empresa',
            color: 'warning'
          })
        }
      }
    }
  }
}
</script>