<template>
  <v-dialog
    v-model="dialog"
    max-width="60%"
    scrollable
  >
    <v-card :loading="load" :disabled="load">
      <v-card-title>
        Modificar desagregos por Local y Concepto
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="">
        <v-form ref="form">
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-8">
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="selectedTipoComp"
                label="Tipo Comprobante"
                item-value="id"
                item-text="nombre"
                :items="tipos_comp.filter(tc => tc.inhabilitado != 1)"
                clearable
                outlined
                dense
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="selectedTipif"
                label="Tipificación"
                item-value="id"
                item-text="nombre"
                :items="tipificaciones.filter(tp => selectedTipoComp != null && selectedTipoComp != undefined && Object.keys(selectedTipoComp).length > 0 && tp.comprobante == selectedTipoComp.id && tp.inhabilitado != 1)"
                clearable
                outlined
                dense
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="ptovta"
                label="Punto de venta"
                item-value="id"
                item-text="nombre"
                :items="all_locales.filter(l => l.empresa == caja.empresa && l.sucursal == caja.sucursal)"
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field
                v-model="importe"
                label="Importe"
                type="number"
                prefix="$"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end py-0">
              <v-btn
                color="success"
                small
                @click="agregar()"
              >
                <v-icon small left>fas fa-plus</v-icon>
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          class="elevation-2"
          :headers="headers"
          :items="desagregos.filter(d => d.estado != 'ELIMINADO')"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.tipo_comprobante`]="{ value }">
            {{ tipos_comp.find(tc => tc.id == value) ? tipos_comp.find(tc => tc.id == value).nombre : '' }}
          </template>
          <template v-slot:[`item.tipificacion`]="{ value }">
            {{ tipificaciones.find(tp => tp.id == value) ? tipificaciones.find(tp => tp.id == value).nombre : '' }}
          </template>
          <template v-slot:[`item.ptovta`]="{ item }">
            {{ all_locales.find(l => l.id == item.ptovta).nombre }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              title="Eliminar"
              color="error"
              small
              @click.stop="eliminar(item)"
            >
              fas fa-trash
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="300"
              type="warning"
              border="left"
              dense
              text
            >
              No hay desagregos
            </v-alert>
          </template>
          <!-- finlas de totales -->
          <template slot="body.append">
            <tr class="rowsel">
              <th></th>
              <th></th>
              <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none">Total desagregado</th>
              <th style="font-size: 14px; font-weight: 600; border-style: groove  none none none" class="text-right">
                {{ formatMoney(total) }}
              </th>
              <th style="border-style: groove  none none none"></th>
            </tr>
          </template>
          <template slot="body.append">
            <tr class="">
              <th></th>
              <th></th>
              <th style="font-size: 14px; font-weight: 400">Total de la transacción </th>
              <th style="font-size: 14px; font-weight: 400" class="text-right">
                {{ formatMoney(caja.total) }}
              </th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="confiirmar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { format_money, objetoNoVacio } from '../../util/utils'

export default {
  data () {
    return {
      formatMoney: format_money,
      total: 0,
      ptovta: null,
      importe: null,
      desagregos: [],
      headers: [
        { text: 'Tipo Comprobante', value: 'tipo_comprobante', sortable: false },
        { text: 'Tipificación', value: 'tipificacion', sortable: false },
        { text: 'Punto de venta', value: 'ptovta', sortable: false },
        { text: 'Importe', value: 'importe', align: 'end', sortable: false, formatter: format_money },
        { text: 'Acciones', value: 'actions', align: 'center',sortable: false }
      ],
      caja: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      selectedTipoComp: {},
      selectedTipif: {},
      load: false
    }
  },
  props: {
    value: Boolean,
    p_caja: Object
  },
  computed: {
    ...mapState(['all_locales']),
    ...mapState('controlCaja', ['tipos_comp', 'tipificaciones']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.caja = JSON.parse(JSON.stringify(this.p_caja))
        this.selectedTipoComp = (this.caja.tipo_comp != null && this.caja.tipo_comp != undefined && this.tipos_comp.filter(element => element.id == this.caja.tipo_comp).length > 0) ? this.tipos_comp.filter(element => element.id == this.caja.tipo_comp)[0] : {}
        this.selectedTipif = (this.caja.tipificacion != null && this.caja.tipificacion != undefined && this.tipificaciones.filter(element => element.id == this.caja.tipificacion).length > 0) ? this.tipificaciones.filter(element => element.id == this.caja.tipificacion)[0] : {}
        if (this.caja.desagregos) {
          this.desagregos = JSON.parse(JSON.stringify(this.caja.desagregos))
          this.calcular_total()
        } else {
          this.desagregos = []
        }
      } else {
        this.ptovta = null
        this.importe = null
        this.total = 0
        this.selectedTipoComp = {}
        this.selectedTipif = {}
      }
    },
    selectedTipoComp: function(){
      if (this.selectedTipoComp == null || this.selectedTipoComp == undefined) this.selectedTipoComp = {}
    },
    selectedTipif: function(){
      if (this.selectedTipif == null || this.selectedTipif == undefined) this.selectedTipif = {}
    }
  },
  methods: {
    confiirmar () {
      // ceunta la cantidad de desagregos no eliminados
      let length = 0
      for (const local of this.desagregos) {
        if (local.estado != 'ELIMINADO') {
          length ++
        }
      }
      // si la cantidad es distinta de 0 procede a realiar otras validaciones
      if (length != 0) {
        if (length < 2) {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar al menos dos desagregos',
            color: 'warning'
          })
          return
        }
        if (parseFloat(this.total).toFixed(2) != parseFloat(this.caja.total).toFixed(2)) {
          this.$store.dispatch('show_snackbar', {
            text: 'El total desagregado no puede ser distinto al total de la transacción',
            color: 'warning',
            timeout: 4000
          })
          return
        }
      }
      this.$emit('desagrego', this.desagregos)
      this.dialog = false
    },
    eliminar (item) {
      // si el desagrego vino con id es pq ya existia, entonces le cambia el estado. sino lo elimina directamente
      if (item.id) {
        item.estado = 'ELIMINADO'
      }
      else {
        this.desagregos.splice(this.desagregos.indexOf(item), 1)
      }
      this.calcular_total()
    },
    agregar () {
      // validaciones
      if (!this.ptovta) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un punto de venta',
          color: 'warning'
        })
        return
      }
      if (!this.importe) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un importe',
          color: 'warning'
        })
        return
      }
      if (this.importe <= 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'El importe debe ser mayor a 0',
          color: 'warning'
        })
        this.importe = null
        return
      }
      if (!objetoNoVacio(this.selectedTipoComp)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar el Tipo de Comprobante',
          color: 'warning'
        })
        this.selectedTipoComp = {}
        return
      }
      if (!objetoNoVacio(this.selectedTipif)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la Tipificación',
          color: 'warning'
        })
        this.selectedTipif = {}
        return
      }

      for (const local of this.desagregos) {
        if (this.ptovta == local.ptovta && this.selectedTipif.id == local.tipificacion && local.estado != 'ELIMINADO') {
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se agregó la tipificacion ' + this.selectedTipif.nombre + ' para el punto de venta ' + this.all_locales.find(l => l.id == local.ptovta).nombre,
            color: 'warning'
          })
          return
        }
      }

      // busca el local para ver si estaba eliminado
      let local = this.desagregos.find(l => l.ptovta == this.ptovta && l.tipificacion == this.selectedTipif.id)

      // si lo encuentra es pq ya estaba y le cambia el estado a modificado. sino lo agrega como NUEVO
      if (local) {
        local.estado = 'MODIFICADO'
        local.importe = this.importe
      }
      else {
        this.desagregos.push({
          id: null,
          id_padre: this.caja.num_comp,
          tipo_comprobante: this.selectedTipoComp.id,
          tipificacion: this.selectedTipif.id,
          ptovta: this.ptovta,
          importe: this.importe,
          estado: 'NUEVO'
        })
      }
      this.calcular_total()
      this.ptovta = null
      this.importe = null
      this.selectedTipoComp = (this.caja.tipo_comp != null && this.caja.tipo_comp != undefined && this.tipos_comp.filter(element => element.id == this.caja.tipo_comp).length > 0) ? this.tipos_comp.filter(element => element.id == this.caja.tipo_comp)[0] : {}
      this.selectedTipif = (this.caja.tipificacion != null && this.caja.tipificacion != undefined && this.tipificaciones.filter(element => element.id == this.caja.tipificacion).length > 0) ? this.tipificaciones.filter(element => element.id == this.caja.tipificacion)[0] : {}
    },
    calcular_total () {
      this.total = 0
      this.desagregos.forEach(desa => {
        if (desa.estado != 'ELIMINADO') {
          this.total += parseFloat(desa.importe)
        }
      })
    }
  }
}
</script>